import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Snatch 4×4\\@75% 1rm`}</p>
    <p>{`then,`}</p>
    <p>{`15-12-9:`}</p>
    <p>{`OHS’s (95/65)(Rx+115/75)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`into,`}</p>
    <p>{`9-7-5:`}</p>
    <p>{`Hang Squat Snatch (95/65)(Rx+115/75)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      